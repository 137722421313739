@import '~breakpoint-sass';

$main-font-family: 'Open Sans', -apple-system, blinkmacsystemfont, segoe ui, roboto, oxygen,
  ubuntu, cantarell, fira sans, droid sans, helvetica neue, sans-serif;
$font-variant-numeric: initial;

// COLORS

$layout-bg: #fff;
$black: #151516;
$white: #fff;
$white-hover: #fafafa;
$border: #e5e5e5;
$primary: #2b9abc;
$primary-hover: #228cac;
$primary-disabled: #afb8bb;
$secondary: #f1705e;
$secondary-hover: #d96656;
$secondary-text: #fff;
$tertiary: #fcc5b6;
$tertiary-hover: #eeb4a4;
$tertiary-text: #151516;
$live: #ff8f80;
$live-hover: #f1705e;
$scroll-up-button-default: rgba($black, 0.6);
$scroll-up-button-hover: rgba($black, 1);
$rating-star-filled: #f3df76;
$rating-star-empty: rgba($white, 0.2);
$social-media-icon-color: #c5eff9;
$social-media-icon-bg: rgba(#fff, 0);
$subscribe-form-bg: #fff;
$subscribe-form-border: #e5e5e5;
$error: #f84a4a;
$error-border: $error;
$content-link: $primary;
$content-widget-bg: #fff3ef;
$placeholder-bg: #ecfbff;
$input-border-focus: #2b9abc;
$notification-default-bg: #f8fcfe;
$notification-success-bg: #f7fbfb;
$notification-warning-bg: #fefdf6;
$notification-error-bg: #fff6f6;
$pushkin-pink: #ff0090;
$pushkin-bar: $primary;
$pushkin-card-bg: #fcc5b6;
$pushkin-card-fill: #151516;
$header-button-search-bg-default: rgb(255 255 255 / 0%);
$header-button-search-bg-hover: rgb(255 255 255 / 0%);
$header-button-search-icon-default: $black;
$header-button-search-icon-hover: $primary-hover;
$header-button-profile-bg-default: rgb(255 255 255 / 0%);
$header-button-profile-bg-hover: rgb(255 255 255 / 0%);
$header-button-profile-icon-default: $black;
$header-button-profile-icon-hover: $primary-hover;
$header-block-bg: #2b9abc;
$header-block-text: #fff;
$header-block-info-button: rgba(#fff, 0.6);
$header-block-hint-bg: rgba(#fff, 0.1);
$header-block-arrows-adaptive: #fff;
$button-bg-default: #ff8f80;
$button-bg-hover: #f1705e;
$button-bg-disabled: #c1bab9;
$button-text-action-default: #151516;
$button-text-price-default: rgb(21 21 22 / 60%);
$button-icon-default: rgb(21 21 22 / 60%);
$button-divider-default: rgb(21 21 22 / 30%);
$button-label-bg: rgb(255 255 255 / 10%);
$button-label-icon: #fff;
$button-vk-bg-default: #07f;
$button-vk-bg-hover: #0060cd;
$card-color-bg-default: #2b9abc;
$card-color-bg-hover: #228cac;
$card-color-text-main: #fff;
$card-color-text-secondary: rgb(255 255 255 / 60%);
$card-color-badge-bg: rgba($primary, 0.7);
$card-main-border: #e5e5e5;
$card-main-bg-default: #fff;
$card-main-bg-hover: #fafafa;
$overview-card-border: #e5e5e5;
$overview-card-bg-default: #fff;
$overview-card-bg-hover: #fafafa;
$overview-card-text: #151516;
$overview-card-badge-bg: rgba($black, 0.05);
$overview-button-text-default: rgba($white, 1);
$overview-button-bg-default: rgba($black, 1);
$overview-button-hover: rgba($black, 1);
$hotel-card-border: #e5e5e5;
$hotel-card-bg-default: #fff;
$hotel-card-bg-hover: #fafafa;
$tickets-bundle-card-border: #e5e5e5;
$tickets-bundle-card-bg-default: #fff;
$tickets-bundle-card-bg-hover: #fafafa;
$stream-card-border: #e5e5e5;
$stream-card-bg-default: #fff;
$stream-card-bg-hover: #fafafa;
$region-videos-card-border: #e5e5e5;
$region-videos-card-bg-default: #fff;
$region-videos-card-bg-hover: #fafafa;
$footer-bg: #c5eff9;
$footer-text-main: #151516;
$footer-text-secondary: rgb(21 21 22 / 60%);
$footer-link-text-default: #151516;
$footer-link-text-hover: #228cac;
$footer-border: rgb(255 255 255 / 40%);
$header-transparent-item-default: $white;
$header-transparent-item-hover: $primary;
$time-tag-bg-active: $white;
$time-tag-border-active: $black;
$excursion-main-button-text-default: $white;
$excursion-main-button-bg-default: #009fe3;
$excursion-main-button-hover: #009fe3;
$excursion-main-card-bg-default: #009fe3;
$excursion-main-card-bg-hover: #0090ce;
$excursion-main-card-text: $white;
$excursion-main-card-badge-bg: rgba($white, 0.2);
$excursion-card-bg-default: $white;
$excursion-card-bg-hover: $white-hover;
$excursion-card-border: $border;
$tour-main-card-bg-default: $white;
$tour-main-card-bg-hover: $white-hover;
$tour-main-card-border: $border;
$tour-main-card-text: $black;
$tour-main-badge-bg: rgba($black, 0.05);
$tour-main-button-text-default: $white;
$tour-main-button-bg-default: $black;
$tour-main-button-hover: $black;
$news-main-card-bg-default: $white;
$news-main-card-bg-hover: $white-hover;
$news-main-card-border: $border;
$news-main-card-text: $black;
$news-main-badge-bg: rgba($black, 0.05);
$news-main-button-text-default: $white;
$news-main-button-bg-default: $black;
$news-main-button-hover: $black;

// HEIGHT CONSTANTS
$event-pay-button-height: 70px;
$event-video-xl: 500px;
$event-video-md: 335px;

// BREAKPOINTS:
// XL = desktop, MD = tablet, SM = mobile
$xl: 1200px;
$md: 1199px 768px;
$sm: 767px 0;
$md-and-sm: 1199px 0;
$xl-and-md: 768px;

// WIDTH CONSTANTS
$event-pay-button-width: 335px;
$event-width-wrapper: 1000px;
$event-width-content: 800px;
$max-width-image: 600px;

// OTHER CONSTANTS
$modal-z-index: 101;
$content-bold-weight: 700;
$card-bg-transition: background-color 0.3s ease;
$timetable-date-padding-top: 10px;

@mixin gap($space) {

  &:not(:last-child) {
    margin-bottom: $space;
  }
}

@mixin scrollbar() {

  &::-webkit-scrollbar-track {
    background-color: $black-5;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 0;
  }

  scrollbar-color: $primary $black-5;
  scrollbar-width: 10px;
}
