
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 102;

  &.fixed {
    position: fixed;
    right: 0;
    bottom: 0;
  }

  &.alignTop {
    padding-top: 20px;
    align-items: flex-start;
  }
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 80%);
}

.img {
  z-index: 1;
  width: auto;
  height: 293px;
  opacity: 0;
  animation: loader 3s ease-in-out infinite;

  img {
    height: 100%;
    width: 100%;
  }
}

@keyframes loader {

  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0;
  }
}
